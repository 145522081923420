<template>
  <div>
    <!-- SYSTEM OPTIONS -->
    <v-row v-if="Object.keys(system.options).length">
      <v-col cols="12" class="pb-0 mb-n3">
        <h2 class="text-subtitle-1">Supported options</h2>
      </v-col>

      <v-col>
        <v-card>
          <v-card-subtitle>
            <v-icon color="accent" left small>mdi-currency-eur</v-icon>
            <span>Currencies</span>
          </v-card-subtitle>

          <v-list class="pa-0" dense>
            <v-list-item v-for="(name, value) in system.options.currencies" :key="value">
              <v-list-item-content>
                <v-list-item-title>{{ name }} ({{ value }})</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-subtitle>
            <v-icon color="accent" left small>mdi-translate</v-icon>
            <span>Languages</span>
          </v-card-subtitle>

          <v-list class="pa-0" dense>
            <v-list-item v-for="(name, value) in system.options.locales" :key="value">
              <v-list-item-content>
                <v-list-item-title>{{ name }} ({{ value }})</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-subtitle>
            <v-icon color="accent" left small>mdi-ticket-confirmation-outline</v-icon>
            <span>Booking Systems [EBS]</span>
          </v-card-subtitle>

          <v-list class="pa-0" dense>
            <v-list-item v-for="(EBS, i) in system.options.EBS" :key="i">
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{ EBS }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-subtitle>
            <v-icon color="accent" left small>mdi-credit-card-multiple-outline</v-icon>
            <span>Payment Systems [EPS]</span>
          </v-card-subtitle>

          <v-list class="pa-0" dense>
            <v-list-item v-for="(EPS, i) in system.options.EPS" :key="i">
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{ EPS }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-subtitle>
            <v-icon color="accent" left small>mdi-truck-outline</v-icon>
            <span>Shipping Systems [ESS]</span>
          </v-card-subtitle>

          <v-list class="pa-0" dense>
            <v-list-item v-for="(ESS, i) in system.options.ESS" :key="i">
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{ ESS }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-subtitle>
            <v-icon color="accent" left small>mdi-taxi</v-icon>
            <span>Taxi Systems [ETS]</span>
          </v-card-subtitle>

          <v-list class="pa-0" dense>
            <v-list-item v-for="(ETS, i) in system.options.ETS" :key="i">
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{ ETS }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- SYSTEM OBJECT ARTICLES -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-1">Purchasable articles</span>
            <v-spacer></v-spacer>
            <v-switch
              hint="Include Hidden"
              v-model="includeHidden"
              @change="initAll()"
              class="ma-0 pa-0 mt-1"
              color="greeny"
              persistent-hint />
            <v-spacer></v-spacer>
            <v-btn @click="createSystemObject('articles')" color="accent" dark>
              <v-icon left>mdi-playlist-plus</v-icon>
              Add new
            </v-btn>
          </v-card-title>

          <v-data-table :headers="systemObjectsHeaders" :items="system.articles" :loading="loading">
            <template v-slot:[`item.title`]="{ item }">
              <b>{{ getTranslationTitle(item.translations) }}</b>
            </template>

            <template v-slot:[`item.translations`]="{ item }">
              <v-chip-group style="max-width: 550px" show-arrows>
                <v-chip v-if="system.options.locales" v-for="(translation, i) in item.translations" :key="i" small>
                  {{ system.options.locales[translation.locale] }}
                </v-chip>
              </v-chip-group>
            </template>

            <template v-slot:[`item.prices`]="{ item }">
              <v-chip-group style="max-width: 300px; display: block" show-arrows>
                <div v-for="(price, i) in item.prices" :key="i">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        small
                        :color="
                          price.exclusiveTo === null ? null : price.exclusiveTo === 'domestic' ? 'primary' : 'secondary'
                        "
                        v-on="on"
                        v-bind="attrs">
                        {{ price.amount }} {{ price.currency }}
                      </v-chip>
                    </template>
                    {{
                      price.exclusiveTo === null
                        ? "Both for Domestic and International"
                        : "Exclusive to " + price.exclusiveTo
                    }}
                  </v-tooltip>
                </div>
              </v-chip-group>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-row justify="end">
                <v-col md="auto">
                  <v-btn @click="editSystemObject('articles', item)" elevation="1" class="third white--text" small>
                    <v-icon left>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                </v-col>

                <v-col md="auto">
                  <v-btn @click="deleteSystemObject('articles', item)" color="primary" icon small>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- SYSTEM OBJECT DISTRIBUTIONS -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-1">Purchasable distribution options</span>
            <v-spacer></v-spacer>
            <v-switch
              hint="Include Hidden"
              v-model="includeHidden"
              @change="initAll()"
              class="ma-0 pa-0 mt-1"
              color="greeny"
              persistent-hint />
            <v-spacer></v-spacer>
            <v-btn @click="createSystemObject('distributions')" color="accent" dark>
              <v-icon left>mdi-playlist-plus</v-icon>
              Add new
            </v-btn>
          </v-card-title>

          <v-data-table :headers="systemObjectsHeaders" :items="system.distributions" :loading="loading">
            <template v-slot:[`item.title`]="{ item }">
              <b>{{ getTranslationTitle(item.translations) }}</b>
            </template>

            <template v-slot:[`item.translations`]="{ item }">
              <v-chip-group style="max-width: 550px" show-arrows>
                <v-chip v-if="system.options.locales" v-for="(translation, i) in item.translations" :key="i" small>
                  {{ system.options.locales[translation.locale] }}
                </v-chip>
              </v-chip-group>
            </template>

            <template v-slot:[`item.prices`]="{ item }">
              <v-chip-group style="max-width: 300px; display: block" show-arrows>
                <div v-for="(price, i) in item.prices" :key="i">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        small
                        :color="
                          price.exclusiveTo === null ? null : price.exclusiveTo === 'domestic' ? 'primary' : 'secondary'
                        "
                        v-on="on"
                        v-bind="attrs">
                        {{ price.amount }} {{ price.currency }}
                      </v-chip>
                    </template>
                    {{
                      price.exclusiveTo === null
                        ? "Both for Domestic and International"
                        : "Exclusive to " + price.exclusiveTo
                    }}
                  </v-tooltip>
                </div>
              </v-chip-group>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-row justify="end">
                <v-col md="auto">
                  <v-btn @click="editSystemObject('distributions', item)" elevation="1" class="third white--text" small>
                    <v-icon left>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                </v-col>

                <v-col md="auto">
                  <v-btn @click="deleteSystemObject('distributions', item)" color="primary" icon small>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- SYSTEM OBJECT MODIFY -->
    <v-bottom-sheet :value="Object.keys(modifying.object).length" attach=".v-main__wrap" scrollable persistent>
      <v-card v-if="Object.keys(modifying.object).length">
        <v-card-title>
          <span v-if="modifying.saving">Saving object...</span>
          <span v-else-if="modifying.object.id">Edit object</span>
          <span v-else>Add new object</span>

          <v-spacer></v-spacer>

          <v-btn @click="modifying.object = {}" icon small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text style="min-height: 55vh; max-height: 80vh">
          <v-form v-model="modifying.valid" ref="systemObject">
            <v-container class="mt-2">
              <v-select
                v-model="modifying.object.category"
                :rules="modifying.validation.notEmpty"
                :items="categories"
                label="Category"
                dense
                outlined
                required></v-select>

              <v-text-field
                v-model="modifying.object.slug"
                :rules="modifying.validation.notEmpty"
                label="Slug"
                placeholder="cancellation_protection"
                dense
                outlined
                required></v-text-field>

              <v-text-field
                v-model="modifying.object.provider"
                :rules="modifying.validation.notEmpty"
                label="Provider"
                placeholder="trainplanet"
                dense
                outlined
                required></v-text-field>

              <div v-if="modifying.type === 'distributions'">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="modifying.object.minDeliveryDays"
                      :rules="modifying.validation.notEmpty"
                      label="Minimum number of delivery days"
                      type="number"
                      dense
                      outlined
                      required></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model.number="modifying.object.maxDeliveryDays"
                      :rules="modifying.validation.notEmpty"
                      label="Maximum number of delivery days"
                      type="number"
                      dense
                      outlined
                      required></v-text-field>
                  </v-col>
                </v-row>

                <v-btn @click="addExternalReference()" class="text-none mb-7 white--text" color="third">
                  <v-icon left dark>mdi-playlist-plus</v-icon>
                  <span>Add external reference</span>
                </v-btn>
                <v-row v-for="(reference, i) in this.modifying.object.externalReferences" :key="i">
                  <v-col cols="6">
                    <v-text-field
                      v-model="reference.value"
                      :rules="modifying.validation.notEmpty"
                      label="External reference"
                      hide-details="auto"
                      dense
                      outlined
                      required></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-text-field
                      v-model="reference.text"
                      :rules="modifying.validation.notEmpty"
                      label="External reference value"
                      hide-details="auto"
                      dense
                      outlined
                      required></v-text-field>
                  </v-col>

                  <v-col cols="1">
                    <v-icon @click="removeExternalReference(i)" color="primary">mdi-delete</v-icon>
                  </v-col>
                </v-row>
              </div>

              <div class="mt-2">
                <v-row cols>
                  <v-col cols="2">
                    <v-btn @click="addSystemObjectPrice()" class="text-none mb-7 third white--text">
                      <v-icon left dark>mdi-playlist-plus</v-icon>
                      <span>Add price</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      class="my-auto"
                      v-model="modifying.object.flexiblePrice"
                      hint="Flexible Price"
                      persistent-hint
                      color="primary" />
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      class="my-auto"
                      v-model="modifying.object.visibleInListing"
                      hint="Visible in Listing (Not Hidden)"
                      persistent-hint
                      color="primary" />
                  </v-col>
                </v-row>
                <v-row v-for="(price, i) in modifying.object.prices" :key="i" cols>
                  <v-col cols="2">
                    <v-text-field
                      v-model.number="price.amount"
                      :rules="modifying.validation.notEmpty"
                      label="Price"
                      type="number"
                      class="price-input"
                      hide-details="auto"
                      dense
                      outlined
                      required></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-text-field
                      v-model.number="price.vat"
                      :rules="modifying.validation.notEmpty"
                      label="VAT %"
                      type="number"
                      hide-details="auto"
                      dense
                      outlined
                      required></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-select
                      v-model="price.currency"
                      :rules="modifying.validation.notEmpty"
                      :items="objectToArray(system.options.currencies)"
                      label="Currency"
                      hide-details="auto"
                      dense
                      outlined
                      required>
                    </v-select>
                  </v-col>

                  <v-col cols="3">
                    <v-select
                      v-model="price.exclusiveTo"
                      :items="['', 'domestic', 'international']"
                      label="Exclusive To"
                      hide-details="auto"
                      dense
                      outlined>
                    </v-select>
                  </v-col>

                  <v-col cols="auto">
                    <v-icon @click="removeSystemObjectPrice(i)" color="primary">mdi-delete</v-icon>
                  </v-col>
                </v-row>
              </div>

              <v-tabs v-model="modifying.tab" class="mt-4">
                <v-tab v-for="(name, value) in system.options.locales" :key="value"> {{ name }} * </v-tab>
              </v-tabs>

              <v-tabs-items v-model="modifying.tab" class="mt-2">
                <v-tab-item v-for="(translation, i) in modifying.object.translations" :key="i">
                  <v-text-field
                    v-model="translation.title"
                    :rules="modifying.validation.notEmpty"
                    class="pt-2"
                    label="Title"
                    outlined
                    dense
                    required></v-text-field>

                  <v-textarea
                    v-model="translation.description"
                    :rules="modifying.validation.notEmpty"
                    label="Description"
                    outlined
                    dense
                    required></v-textarea>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="saveSystemObject()" :loading="modifying.saving" color="accent" block>Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { makeCopy } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";

export default {
  name: "Settings",
  data: () => ({
    loading: false,
    includeHidden: true,
    modifying: {
      tab: null,
      saving: false,
      valid: false,
      validation: {
        notEmpty: [(v) => v !== null || "Required"],
      },
      type: null,
      object: {},
    },
    categories: [
      {
        text: "Digital",
        value: "digital",
      },
      {
        text: "Physical",
        value: "physical",
      },
    ],
    system: {
      options: {},
      articles: [],
      distributions: [],
    },
    systemObjectsHeaders: [
      {
        text: "Title",
        value: "title",
        width: "250px",
      },
      {
        text: "Slug",
        value: "slug",
        width: "230px",
      },
      {
        text: "Provider",
        value: "provider",
        width: "150px",
      },
      {
        text: "Category",
        value: "category",
        width: "150px",
      },
      {
        text: "Translations",
        value: "translations",
        width: "300px",
      },
      {
        text: "Prices",
        value: "prices",
        width: "200px",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
  }),
  async mounted() {
    await this.initAll();
  },
  watch: {
    "modifying.object": {
      deep: true,
      handler: function (v) {
        if (v.prices) {
          v.prices.forEach((price) => {
            if (price.exclusiveTo === "") {
              price.exclusiveTo = null;
            }
          });
        }
        // this.$nextTick(() => this.modifying.object = v);
      },
    },
  },
  methods: {
    async initAll() {
      this.loading = true;

      await Promise.all([this.getOptions(), this.listArticles(), this.listDistributions()]);

      this.loading = false;
    },
    async getOptions() {
      this.system.options = await Trainplanet.getOrderSystemOptions();
    },
    async listArticles() {
      this.system.articles = await Trainplanet.listOrderSystemArticles({
        includeHidden: this.includeHidden,
      });
    },
    async listDistributions() {
      this.system.distributions = await Trainplanet.listOrderSystemDistributions({
        includeHidden: this.includeHidden,
      });
    },
    getTranslationTitle(translations) {
      const translation = translations.find((x) => x.locale === "en-SE");
      if (translation) return translation.title;
      return translations[0].title;
    },
    objectToArray(object) {
      return Object.entries(object).map(([key, value]) => {
        if (typeof value === "object" && value !== null && "value" in value && "text" in value) {
          return value;
        } else {
          return { value: key, text: value };
        }
      });
    },
    arrayToObject(array) {
      return array.reduce((map, object) => {
        const data = map;
        data[object.value] = object.text;
        return data;
      }, {});
    },
    createSystemObject(type) {
      this.modifying.type = type;
      this.modifying.object = {
        slug: null,
        category: null,
        provider: null,
        translations: Object.entries(this.system.options.locales).map(([locale]) => ({
          locale,
          title: null,
          description: null,
        })),
        prices: [
          {
            amount: 0,
            vat: 0,
            currency: null,
          },
        ],
      };

      if (type === "distributions") {
        this.modifying.object.minDeliveryDays = 0;
        this.modifying.object.maxDeliveryDays = 0;
        this.modifying.object.externalReferences = [];
        this.addExternalReference();
      }
    },
    editSystemObject(type, object) {
      this.modifying.type = type;

      if (type === "distributions") {
        /* eslint-disable no-param-reassign */
        object.externalReferences = this.objectToArray(object.externalReferences);
      }

      const translations = Object.entries(this.system.options.locales)
        .map((item) => item[0])
        .map((locale) => {
          const translationItem = object.translations.find((r) => r.locale === locale);
          if (translationItem) {
            return translationItem;
          } else {
            return {
              description: "",
              title: "",
              locale,
            };
          }
        });

      const temp = {
        ...object,
        translations,
      };

      this.modifying.object = makeCopy(temp);
    },
    addExternalReference() {
      this.modifying.object.externalReferences.push({
        value: null,
        text: null,
      });
    },
    removeExternalReference(key) {
      if (this.modifying.object.externalReferences <= 1) return;
      this.modifying.object.externalReferences.splice(key, 1);
    },
    addSystemObjectPrice() {
      this.modifying.object.prices.push({
        amount: 0,
        vat: 0,
        currency: null,
      });
    },
    removeSystemObjectPrice(key) {
      if (this.modifying.object.prices.length <= 1) return;
      this.modifying.object.prices.splice(key, 1);
    },
    async saveSystemObject() {
      this.modifying.saving = true;

      if (this.$refs.systemObject.validate()) {
        if (this.modifying.type === "distributions") {
          this.modifying.object.externalReferences = this.arrayToObject(this.modifying.object.externalReferences);
        }

        if (this.modifying.object.id) {
          const { id } = this.modifying.object;
          delete this.modifying.object.id;

          const object = await Trainplanet.updateOrderSystemObject(this.modifying.type, id, this.modifying.object);

          const key = this.system[this.modifying.type].findIndex((x) => x.id === id);
          this.$set(this.system[this.modifying.type], key, object);
        } else {
          const object = await Trainplanet.createOrderSystemObject(this.modifying.type, this.modifying.object);
          this.system[this.modifying.type].push(object);
        }

        this.modifying.object = {};
      }

      this.modifying.saving = false;
    },
    async deleteSystemObject(type, item) {
      this.loading = true;

      this.system[type] = this.system[type].filter((x) => x.id !== item.id); // Remove from list
      await Trainplanet.deleteOrderSystemObject(item.id);

      this.loading = false;
    },
  },
};
</script>

<style>
.price-input .v-input__prepend-outer,
.price-input .v-input__append-outer {
  margin-top: 0 !important;
}

.v-chip {
  min-width: 80px;
}
</style>
