var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [Object.keys(_vm.system.options).length ? _c('v-row', [_c('v-col', {
    staticClass: "pb-0 mb-n3",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("Supported options")])]), _c('v-col', [_c('v-card', [_c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "color": "accent",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-currency-eur")]), _c('span', [_vm._v("Currencies")])], 1), _c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.system.options.currencies, function (name, value) {
    return _c('v-list-item', {
      key: value
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(name) + " (" + _vm._s(value) + ")")])], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', [_c('v-card', [_c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "color": "accent",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-translate")]), _c('span', [_vm._v("Languages")])], 1), _c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.system.options.locales, function (name, value) {
    return _c('v-list-item', {
      key: value
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(name) + " (" + _vm._s(value) + ")")])], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', [_c('v-card', [_c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "color": "accent",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-ticket-confirmation-outline")]), _c('span', [_vm._v("Booking Systems [EBS]")])], 1), _c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.system.options.EBS, function (EBS, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(EBS))])], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', [_c('v-card', [_c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "color": "accent",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-credit-card-multiple-outline")]), _c('span', [_vm._v("Payment Systems [EPS]")])], 1), _c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.system.options.EPS, function (EPS, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(EPS))])], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', [_c('v-card', [_c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "color": "accent",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-truck-outline")]), _c('span', [_vm._v("Shipping Systems [ESS]")])], 1), _c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.system.options.ESS, function (ESS, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(ESS))])], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', [_c('v-card', [_c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "color": "accent",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-taxi")]), _c('span', [_vm._v("Taxi Systems [ETS]")])], 1), _c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.system.options.ETS, function (ETS, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(ETS))])], 1)], 1);
  }), 1)], 1)], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("Purchasable articles")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "ma-0 pa-0 mt-1",
    attrs: {
      "hint": "Include Hidden",
      "color": "greeny",
      "persistent-hint": ""
    },
    on: {
      "change": function ($event) {
        return _vm.initAll();
      }
    },
    model: {
      value: _vm.includeHidden,
      callback: function ($$v) {
        _vm.includeHidden = $$v;
      },
      expression: "includeHidden"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.createSystemObject('articles');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _vm._v(" Add new ")], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.systemObjectsHeaders,
      "items": _vm.system.articles,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: `item.title`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b', [_vm._v(_vm._s(_vm.getTranslationTitle(item.translations)))])];
      }
    }, {
      key: `item.translations`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-chip-group', {
          staticStyle: {
            "max-width": "550px"
          },
          attrs: {
            "show-arrows": ""
          }
        }, _vm._l(item.translations, function (translation, i) {
          return _vm.system.options.locales ? _c('v-chip', {
            key: i,
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.system.options.locales[translation.locale]) + " ")]) : _vm._e();
        }), 1)];
      }
    }, {
      key: `item.prices`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-chip-group', {
          staticStyle: {
            "max-width": "300px",
            "display": "block"
          },
          attrs: {
            "show-arrows": ""
          }
        }, _vm._l(item.prices, function (price, i) {
          return _c('div', {
            key: i
          }, [_c('v-tooltip', {
            attrs: {
              "top": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref4) {
                var on = _ref4.on,
                  attrs = _ref4.attrs;
                return [_c('v-chip', _vm._g(_vm._b({
                  attrs: {
                    "small": "",
                    "color": price.exclusiveTo === null ? null : price.exclusiveTo === 'domestic' ? 'primary' : 'secondary'
                  }
                }, 'v-chip', attrs, false), on), [_vm._v(" " + _vm._s(price.amount) + " " + _vm._s(price.currency) + " ")])];
              }
            }], null, true)
          }, [_vm._v(" " + _vm._s(price.exclusiveTo === null ? "Both for Domestic and International" : "Exclusive to " + price.exclusiveTo) + " ")])], 1);
        }), 0)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "third white--text",
          attrs: {
            "elevation": "1",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editSystemObject('articles', item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Edit ")], 1)], 1), _c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "color": "primary",
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteSystemObject('articles', item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("Purchasable distribution options")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "ma-0 pa-0 mt-1",
    attrs: {
      "hint": "Include Hidden",
      "color": "greeny",
      "persistent-hint": ""
    },
    on: {
      "change": function ($event) {
        return _vm.initAll();
      }
    },
    model: {
      value: _vm.includeHidden,
      callback: function ($$v) {
        _vm.includeHidden = $$v;
      },
      expression: "includeHidden"
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.createSystemObject('distributions');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _vm._v(" Add new ")], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.systemObjectsHeaders,
      "items": _vm.system.distributions,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: `item.title`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b', [_vm._v(_vm._s(_vm.getTranslationTitle(item.translations)))])];
      }
    }, {
      key: `item.translations`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-chip-group', {
          staticStyle: {
            "max-width": "550px"
          },
          attrs: {
            "show-arrows": ""
          }
        }, _vm._l(item.translations, function (translation, i) {
          return _vm.system.options.locales ? _c('v-chip', {
            key: i,
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.system.options.locales[translation.locale]) + " ")]) : _vm._e();
        }), 1)];
      }
    }, {
      key: `item.prices`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-chip-group', {
          staticStyle: {
            "max-width": "300px",
            "display": "block"
          },
          attrs: {
            "show-arrows": ""
          }
        }, _vm._l(item.prices, function (price, i) {
          return _c('div', {
            key: i
          }, [_c('v-tooltip', {
            attrs: {
              "top": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref9) {
                var on = _ref9.on,
                  attrs = _ref9.attrs;
                return [_c('v-chip', _vm._g(_vm._b({
                  attrs: {
                    "small": "",
                    "color": price.exclusiveTo === null ? null : price.exclusiveTo === 'domestic' ? 'primary' : 'secondary'
                  }
                }, 'v-chip', attrs, false), on), [_vm._v(" " + _vm._s(price.amount) + " " + _vm._s(price.currency) + " ")])];
              }
            }], null, true)
          }, [_vm._v(" " + _vm._s(price.exclusiveTo === null ? "Both for Domestic and International" : "Exclusive to " + price.exclusiveTo) + " ")])], 1);
        }), 0)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "third white--text",
          attrs: {
            "elevation": "1",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editSystemObject('distributions', item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Edit ")], 1)], 1), _c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "color": "primary",
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteSystemObject('distributions', item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1), _c('v-bottom-sheet', {
    attrs: {
      "value": Object.keys(_vm.modifying.object).length,
      "attach": ".v-main__wrap",
      "scrollable": "",
      "persistent": ""
    }
  }, [Object.keys(_vm.modifying.object).length ? _c('v-card', [_c('v-card-title', [_vm.modifying.saving ? _c('span', [_vm._v("Saving object...")]) : _vm.modifying.object.id ? _c('span', [_vm._v("Edit object")]) : _c('span', [_vm._v("Add new object")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.modifying.object = {};
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "min-height": "55vh",
      "max-height": "80vh"
    }
  }, [_c('v-form', {
    ref: "systemObject",
    model: {
      value: _vm.modifying.valid,
      callback: function ($$v) {
        _vm.$set(_vm.modifying, "valid", $$v);
      },
      expression: "modifying.valid"
    }
  }, [_c('v-container', {
    staticClass: "mt-2"
  }, [_c('v-select', {
    attrs: {
      "rules": _vm.modifying.validation.notEmpty,
      "items": _vm.categories,
      "label": "Category",
      "dense": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.modifying.object.category,
      callback: function ($$v) {
        _vm.$set(_vm.modifying.object, "category", $$v);
      },
      expression: "modifying.object.category"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.modifying.validation.notEmpty,
      "label": "Slug",
      "placeholder": "cancellation_protection",
      "dense": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.modifying.object.slug,
      callback: function ($$v) {
        _vm.$set(_vm.modifying.object, "slug", $$v);
      },
      expression: "modifying.object.slug"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.modifying.validation.notEmpty,
      "label": "Provider",
      "placeholder": "trainplanet",
      "dense": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.modifying.object.provider,
      callback: function ($$v) {
        _vm.$set(_vm.modifying.object, "provider", $$v);
      },
      expression: "modifying.object.provider"
    }
  }), _vm.modifying.type === 'distributions' ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.modifying.validation.notEmpty,
      "label": "Minimum number of delivery days",
      "type": "number",
      "dense": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.modifying.object.minDeliveryDays,
      callback: function ($$v) {
        _vm.$set(_vm.modifying.object, "minDeliveryDays", _vm._n($$v));
      },
      expression: "modifying.object.minDeliveryDays"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.modifying.validation.notEmpty,
      "label": "Maximum number of delivery days",
      "type": "number",
      "dense": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.modifying.object.maxDeliveryDays,
      callback: function ($$v) {
        _vm.$set(_vm.modifying.object, "maxDeliveryDays", _vm._n($$v));
      },
      expression: "modifying.object.maxDeliveryDays"
    }
  })], 1)], 1), _c('v-btn', {
    staticClass: "text-none mb-7 white--text",
    attrs: {
      "color": "third"
    },
    on: {
      "click": function ($event) {
        return _vm.addExternalReference();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _c('span', [_vm._v("Add external reference")])], 1), _vm._l(this.modifying.object.externalReferences, function (reference, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "rules": _vm.modifying.validation.notEmpty,
        "label": "External reference",
        "hide-details": "auto",
        "dense": "",
        "outlined": "",
        "required": ""
      },
      model: {
        value: reference.value,
        callback: function ($$v) {
          _vm.$set(reference, "value", $$v);
        },
        expression: "reference.value"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "5"
      }
    }, [_c('v-text-field', {
      attrs: {
        "rules": _vm.modifying.validation.notEmpty,
        "label": "External reference value",
        "hide-details": "auto",
        "dense": "",
        "outlined": "",
        "required": ""
      },
      model: {
        value: reference.text,
        callback: function ($$v) {
          _vm.$set(reference, "text", $$v);
        },
        expression: "reference.text"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.removeExternalReference(i);
        }
      }
    }, [_vm._v("mdi-delete")])], 1)], 1);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "mt-2"
  }, [_c('v-row', {
    attrs: {
      "cols": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mb-7 third white--text",
    on: {
      "click": function ($event) {
        return _vm.addSystemObjectPrice();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-playlist-plus")]), _c('span', [_vm._v("Add price")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-switch', {
    staticClass: "my-auto",
    attrs: {
      "hint": "Flexible Price",
      "persistent-hint": "",
      "color": "primary"
    },
    model: {
      value: _vm.modifying.object.flexiblePrice,
      callback: function ($$v) {
        _vm.$set(_vm.modifying.object, "flexiblePrice", $$v);
      },
      expression: "modifying.object.flexiblePrice"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-switch', {
    staticClass: "my-auto",
    attrs: {
      "hint": "Visible in Listing (Not Hidden)",
      "persistent-hint": "",
      "color": "primary"
    },
    model: {
      value: _vm.modifying.object.visibleInListing,
      callback: function ($$v) {
        _vm.$set(_vm.modifying.object, "visibleInListing", $$v);
      },
      expression: "modifying.object.visibleInListing"
    }
  })], 1)], 1), _vm._l(_vm.modifying.object.prices, function (price, i) {
    return _c('v-row', {
      key: i,
      attrs: {
        "cols": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-text-field', {
      staticClass: "price-input",
      attrs: {
        "rules": _vm.modifying.validation.notEmpty,
        "label": "Price",
        "type": "number",
        "hide-details": "auto",
        "dense": "",
        "outlined": "",
        "required": ""
      },
      model: {
        value: price.amount,
        callback: function ($$v) {
          _vm.$set(price, "amount", _vm._n($$v));
        },
        expression: "price.amount"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-text-field', {
      attrs: {
        "rules": _vm.modifying.validation.notEmpty,
        "label": "VAT %",
        "type": "number",
        "hide-details": "auto",
        "dense": "",
        "outlined": "",
        "required": ""
      },
      model: {
        value: price.vat,
        callback: function ($$v) {
          _vm.$set(price, "vat", _vm._n($$v));
        },
        expression: "price.vat"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-select', {
      attrs: {
        "rules": _vm.modifying.validation.notEmpty,
        "items": _vm.objectToArray(_vm.system.options.currencies),
        "label": "Currency",
        "hide-details": "auto",
        "dense": "",
        "outlined": "",
        "required": ""
      },
      model: {
        value: price.currency,
        callback: function ($$v) {
          _vm.$set(price, "currency", $$v);
        },
        expression: "price.currency"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_c('v-select', {
      attrs: {
        "items": ['', 'domestic', 'international'],
        "label": "Exclusive To",
        "hide-details": "auto",
        "dense": "",
        "outlined": ""
      },
      model: {
        value: price.exclusiveTo,
        callback: function ($$v) {
          _vm.$set(price, "exclusiveTo", $$v);
        },
        expression: "price.exclusiveTo"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.removeSystemObjectPrice(i);
        }
      }
    }, [_vm._v("mdi-delete")])], 1)], 1);
  })], 2), _c('v-tabs', {
    staticClass: "mt-4",
    model: {
      value: _vm.modifying.tab,
      callback: function ($$v) {
        _vm.$set(_vm.modifying, "tab", $$v);
      },
      expression: "modifying.tab"
    }
  }, _vm._l(_vm.system.options.locales, function (name, value) {
    return _c('v-tab', {
      key: value
    }, [_vm._v(" " + _vm._s(name) + " * ")]);
  }), 1), _c('v-tabs-items', {
    staticClass: "mt-2",
    model: {
      value: _vm.modifying.tab,
      callback: function ($$v) {
        _vm.$set(_vm.modifying, "tab", $$v);
      },
      expression: "modifying.tab"
    }
  }, _vm._l(_vm.modifying.object.translations, function (translation, i) {
    return _c('v-tab-item', {
      key: i
    }, [_c('v-text-field', {
      staticClass: "pt-2",
      attrs: {
        "rules": _vm.modifying.validation.notEmpty,
        "label": "Title",
        "outlined": "",
        "dense": "",
        "required": ""
      },
      model: {
        value: translation.title,
        callback: function ($$v) {
          _vm.$set(translation, "title", $$v);
        },
        expression: "translation.title"
      }
    }), _c('v-textarea', {
      attrs: {
        "rules": _vm.modifying.validation.notEmpty,
        "label": "Description",
        "outlined": "",
        "dense": "",
        "required": ""
      },
      model: {
        value: translation.description,
        callback: function ($$v) {
          _vm.$set(translation, "description", $$v);
        },
        expression: "translation.description"
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "loading": _vm.modifying.saving,
      "color": "accent",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.saveSystemObject();
      }
    }
  }, [_vm._v("Save ")])], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }